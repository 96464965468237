export const state = () => ({
    wishlist: [],
});

export const getters = {
    getWishlist(state) {
        return state.wishlist;
    },
};

export const mutations = {
    setWishlist(state, wishlist) {
        state.wishlist = [...wishlist];
    },
    addProductToWishlist(state, item) {
        state.wishlist.push({ ...item });
    },
    removeProductFromWishlist(state, itemId) {
        state.wishlist = state.wishlist.filter((item) => item.id != itemId);
    },
    resetWishlist(state) {
        state.wishlist = [];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
