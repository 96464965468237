import { has, get } from 'lodash';
export default {
    computed: {
        getLocale() {
            return (obj, key) => {
                const myLocale = this.$i18n.locale.toLowerCase().includes('pt') ? 'pt' : this.$i18n.locale;
                if (has(obj, 'localizations')) {
                    const locale = obj.localizations.find((l) => l.locale == myLocale);
                    if (locale) return get(locale, key);
                }
                return get(obj, key);
            };
        },
    },
    methods: {
        getImgUrl(img, size = 'original') {
            if (img == 'placeholder') return '/assets/img/product/placeholder_small.png';
            let uri = img.url;

            const uriSize = get(img, `formats.${size}.url`);
            if (uriSize) uri = uriSize;
            // else console.warn(`${img.name} has no ${size} size`);

            if (img.id == 0) return uri;
            return process.env.VUE_APP_MEDIA_URL + uri;
        },
        onImgError(e) {
            e.target.src = '/assets/img/product/placeholder_small.png';
        },
    },
};
