import cartModel from '@/models/cart.js';
export const state = () => ({
    cart: cartModel(),
});

export const getters = {
    getCart(state) {
        return state.cart;
    },
    isProductInCart(state) {
        return (productSlug, simulationId) => {
            return simulationId
                ? state.cart.cartProducts.findIndex(
                      (item) =>
                          item.product.slug == productSlug && item.simulation && item.simulation.id == simulationId,
                  ) !== -1
                : state.cart.cartProducts.findIndex((item) => item.product.slug == productSlug && !item.simulation) !==
                      -1;
        };
    },
};

export const mutations = {
    setCart(state, cart) {
        state.cart = cart;
    },
    addProductToCart(state, _item) {
        // check if product is in cart, if it is then sum the quantity
        const item = !_item.simulation
            ? state.cart.cartProducts.find((item) => item.product.slug == _item.product.slug)
            : state.cart.cartProducts.find(
                  (item) =>
                      item.product.slug == _item.product.slug &&
                      item.simulation &&
                      item.simulation.id == _item.simulation.id,
              );
        if (item && !item.product.isCustomizable) {
            item.quantity = _item.quantity;
        } else {
            state.cart.cartProducts.push(_item);
        }
    },
    updateQuantity(state, { itemId, quantity }) {
        const item = state.cart.cartProducts.find((item) => item.id == itemId);
        item.quantity = quantity;
    },
    updateSize(state, { itemId, size }) {
        const item = state.cart.cartProducts.find((item) => item.id == itemId);
        item.size = size;
    },
    removeProductFromCart(state, itemId) {
        console.log(itemId);
        state.cart.cartProducts = state.cart.cartProducts.filter((item) => item.id != itemId);
    },
    clearCart(state) {
        state.cart.cartProducts = [];
    },
    resetCart(state) {
        state.cart = cartModel();
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
