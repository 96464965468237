import axios from '@/plugins/@iotechpis/axios';
import qs from 'qs';

const DEFAULT_POPULATES = [
    'product.localizations',
    'product.category.localizations',
    'product.media',
    'simulation.picture',
    'simulation.pdf',
];

export function createCart(data) {
    const query = qs.stringify({
        populate: ['cartProducts'],
    });
    return axios.post(`/carts?${query}`, { data });
}

export function getCart(query = {}) {
    query = qs.stringify(query);
    return axios.get(`/carts?${query}`);
}

export function updateCart(id, data, query = {}) {
    query = qs.stringify(query);
    return axios.put(`/carts/${id}?${query}`, { data });
}

export function addCartItem(data) {
    const query = qs.stringify({
        populate: DEFAULT_POPULATES,
    });
    return axios.post('/cart-products?' + query, { data });
}

export function removeCartItem(id) {
    return axios.remove(`/cart-products/${id}`);
}

export function updateCartItem(id, data) {
    const query = qs.stringify({
        populate: DEFAULT_POPULATES,
    });
    return axios.put(`/cart-products/${id}?${query}`, { data });
}
