import { get, post, put } from '@/plugins/@iotechpis/axios';
import qs from 'qs';

const DEFAULT_POPULATES = [
    'wishlist.product.localizations',
    'wishlist.product.media',
    'wishlist.product.category',
    'wishlist.product.category.localizations',
    'wishlist.simulation.picture',
    'wishlist.simulation.pdf',
    'cart.cartProducts.product.media',
    'cart.cartProducts.product.category',
    'cart.cartProducts.product.localizations',
    'cart.cartProducts.product.category.localizations',
    'cart.cartProducts.simulation.picture',
    'cart.cartProducts.simulation.pdf',
    'picture',
    'notifications',
];

export function login(data) {
    const query = qs.stringify({
        populate: DEFAULT_POPULATES,
    });
    return post(`/auth/local/?${query}`, data);
}

export function googleLogin(data) {
    return post('/auth/google', data);
}

export function facebookLogin(data) {
    return post('/auth/facebook', data);
}

export function appleLogin(data) {
    return post('/auth/apple', data);
}

export function tokenAuth() {
    const query = qs.stringify({
        populate: DEFAULT_POPULATES,
    });
    return get(`/users/me?${query}`);
}

export function updateUser(data) {
    const query = qs.stringify({
        populate: DEFAULT_POPULATES,
    });
    return put('/auth/me?' + query, data);
}

export function registerUser(data) {
    const query = qs.stringify({
        populate: DEFAULT_POPULATES,
    });
    return post(`/auth/local/register?${query}`, data);
}

export function forgotPassword(data) {
    const query = qs.stringify({
        populate: DEFAULT_POPULATES,
    });
    return post(`/auth/forgot-password?${query}`, data);
}

export function resetPassword(data) {
    const query = qs.stringify({
        populate: DEFAULT_POPULATES,
    });
    return post(`/auth/reset-password?${query}`, data);
}

export function changePassword(data) {
    const query = qs.stringify({
        populate: DEFAULT_POPULATES,
    });
    return post(`/auth/change-password?${query}`, data);
}
