<template>
    <nav class="navbar py-3" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <a
                role="button"
                class="navbar-burger ml-0"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
                :class="{ 'is-active': isBurgerOpen }"
                @click="toggleBurguerOpen()">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
            <img class="image is-48x48 ml-3 mt-1" src="@/assets/img/logo.png" @click="goTo({ name: 'Home' })" />
            <div class="buttons ml-auto is-hidden-widescreen">
                <b-button
                    v-if="$store.getters['auth/getToken'] == null"
                    class="button is-primary is-small has-text-weight-semibold py-4"
                    @click="goTo({ name: 'Login' })">
                    {{ $t('navbar.loginRegister') }}
                </b-button>
                <div v-else>
                    <b-button @click="goTo({ name: 'Cart' })" class="is-ghost has-text-grey-light px-0">
                        <figure class="is-relative">
                            <span class="badge is-bottom-right is-danger">{{ getCartCount }}</span>
                            <img class="badgeimage" src="@/assets/icons/shopping_bag.png" />
                        </figure>
                    </b-button>
                    <b-dropdown class="dropdown px-0" aria-role="list">
                        <template #trigger="{ active }">
                            <b-button
                                class="btn-account"
                                icon-pack="fas"
                                icon-left="user"
                                type="is-primary"
                                :icon-right="active ? 'angle-up' : 'angle-down'"
                                ><p class="is-hidden-mobile">
                                    {{ $store.getters['auth/getUser'].firstName }}
                                </p></b-button
                            >
                        </template>
                        <b-dropdown-item
                            @click="goTo({ name: 'MyAccount', query: { tab: 'profile' } })"
                            aria-role="listitem"
                            >{{ $t('navbar.myAccount') }}</b-dropdown-item
                        >
                        <b-dropdown-item
                            @click="goTo({ name: 'MyAccount', query: { tab: 'wishlist' } })"
                            aria-role="listitem">
                            {{ $t('navbar.myWishlist') }}
                        </b-dropdown-item>
                        <b-dropdown-item
                            @click="goTo({ name: 'MyAccount', query: { tab: 'orders' } })"
                            aria-role="listitem">
                            {{ $t('navbar.myOrders') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="logOut" aria-role="listitem">{{
                            $t('navbar.logout')
                        }}</b-dropdown-item>
                    </b-dropdown>
                </div>
                <b-dropdown class="is-small is-right is-active px-2" v-model="currentLanguage" aria-role="list">
                    <template v-if="$store.getters['language/getLanguage'] == 'en'" #trigger>
                        <b-button icon-pack="fas" icon-right="angle-down" class="pl-2 pr-3 btn-flag">
                            <img class="is-flex is-align-self-flex-start flag" src="@/assets/img/navbar/uk.png" />
                        </b-button>
                    </template>

                    <template v-else #trigger>
                        <b-button icon-pack="fas" icon-right="angle-down" class="pl-2 pr-3 btn-flag">
                            <img class="is-flex is-align-self-flex-start flag" src="@/assets/img/navbar/pt.png" />
                        </b-button>
                    </template>

                    <b-dropdown-item @click="setLanguage('en')" :value="true" aria-role="listitem">
                        <div class="media is-align-items-center">
                            <img class="flag" src="@/assets/img/navbar/uk.png" />
                            <div class="media-content ml-3 is-flex is-align-self-center">
                                <h3>{{ $t('navbar.english') }}</h3>
                            </div>
                        </div>
                    </b-dropdown-item>

                    <b-dropdown-item @click="setLanguage('pt_PT')" :value="false" aria-role="listitem">
                        <div class="media is-align-items-center">
                            <img class="flag" src="@/assets/img/navbar/pt.png" />
                            <div class="media-content ml-3 is-flex is-align-self-center">
                                <h3>{{ $t('navbar.portuguese') }}</h3>
                            </div>
                        </div>
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
        <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': isBurgerOpen }">
            <div class="navbar-start">
                <b-navbar-item
                    class="pl-5-desktop is-size-7 has-text-weight-medium"
                    :class="$route.name === 'Home' ? 'has-text-primary has-text-weight-bold' : ''"
                    @click="goTo({ name: 'Home' })">
                    {{ $t('navbar.home') }}
                </b-navbar-item>
                <b-navbar-item
                    class="is-size-7 has-text-weight-medium"
                    :class="$route.name === 'Lookbook' ? 'has-text-primary has-text-weight-semibold' : ''"
                    @click="goTo({ name: 'Lookbook' })">
                    {{ $t('navbar.lookbook') }}
                </b-navbar-item>
                <b-navbar-item
                    class="is-size-7 has-text-weight-medium"
                    :class="
                        $route.name === 'Simulator' || $route.name === 'CustomizeProduct'
                            ? 'has-text-primary has-text-weight-semibold'
                            : ''
                    "
                    @click="goTo({ name: 'Simulator' })">
                    {{ $t('navbar.simulator') }}
                </b-navbar-item>
                <b-navbar-item
                    class="is-size-7 has-text-weight-medium"
                    :class="$route.name === 'Acessories' ? 'has-text-primary has-text-weight-semibold' : ''"
                    @click="goTo({ name: 'Acessories' })">
                    {{ $t('navbar.acessories') }}
                </b-navbar-item>
                <b-navbar-item
                    class="is-size-7 has-text-weight-medium"
                    :class="$route.name === 'Company' ? 'has-text-primary has-text-weight-semibold' : ''"
                    @click="goTo({ name: 'Company' })">
                    {{ $t('navbar.company') }}
                </b-navbar-item>
                <b-navbar-item
                    class="is-size-7 has-text-weight-medium"
                    :class="$route.name === 'Processes' ? 'has-text-primary has-text-weight-semibold' : ''"
                    @click="goTo({ name: 'Processes' })">
                    {{ $t('navbar.processes') }}
                </b-navbar-item>
                <b-navbar-item
                    class="is-size-7 has-text-weight-medium"
                    :class="
                        $route.name === 'RawMaterials' ||
                        $route.name === 'CleaningProducts' ||
                        $route.name === 'OurLeathers'
                            ? 'has-text-primary has-text-primary has-text-weight-semibold'
                            : ''
                    "
                    @click="goTo({ name: 'RawMaterials' })">
                    {{ $t('navbar.rawMaterials') }}
                </b-navbar-item>
                <b-navbar-item
                    class="is-size-7 has-text-weight-medium"
                    :class="$route.name === 'Contact' ? 'has-text-primary has-text-weight-semibold' : ''"
                    @click="goTo({ name: 'Contact' })">
                    {{ $t('navbar.contact') }}
                </b-navbar-item>
            </div>
            <div class="navbar-end is-hidden-desktop-only is-hidden-touch">
                <div class="buttons">
                    <b-button
                        v-if="$store.getters['auth/getToken'] == null"
                        class="button is-primary is-small has-text-weight-semibold py-4"
                        @click="goTo({ name: 'Login' })">
                        {{ $t('navbar.loginRegister') }}
                    </b-button>
                    <div v-else>
                        <b-button @click="goTo({ name: 'Cart' })" class="is-ghost is-small">
                            <figure class="is-relative">
                                <span class="badge is-bottom-right is-danger">{{ getCartCount }}</span>
                                <img class="badgeimage" src="@/assets/icons/shopping_bag.png" />
                            </figure>
                        </b-button>
                        <b-dropdown class="dropdown" aria-role="list">
                            <template #trigger="{ active }">
                                <b-button
                                    icon-pack="fas"
                                    icon-left="user"
                                    :label="$store.getters['auth/getUser'].firstName"
                                    type="is-primary"
                                    :icon-right="active ? 'angle-up' : 'angle-down'" />
                            </template>

                            <b-dropdown-item
                                @click="goTo({ name: 'MyAccount', query: { tab: 'profile' } })"
                                aria-role="listitem"
                                >{{ $t('navbar.myAccount') }}</b-dropdown-item
                            >
                            <b-dropdown-item
                                @click="goTo({ name: 'MyAccount', query: { tab: 'wishlist' } })"
                                aria-role="listitem">
                                {{ $t('navbar.myWishlist') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="goTo({ name: 'MyAccount', query: { tab: 'orders' } })"
                                aria-role="listitem">
                                {{ $t('navbar.myOrders') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="logOut" aria-role="listitem">{{
                                $t('navbar.logout')
                            }}</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <b-dropdown class="is-small is-right is-active px-2" v-model="currentLanguage" aria-role="list">
                        <template v-if="$store.getters['language/getLanguage'] == 'en'" #trigger>
                            <b-button class="btn-flag" icon-pack="fas" icon-right="angle-down"
                                ><img class="is-flex is-align-self-flex-start flag" src="@/assets/img/navbar/uk.png"
                            /></b-button>
                        </template>

                        <template v-else #trigger>
                            <b-button class="btn-flag" icon-pack="fas" icon-right="angle-down">
                                <img class="is-flex is-align-self-flex-start flag" src="@/assets/img/navbar/pt.png" />
                            </b-button>
                        </template>

                        <b-dropdown-item @click="setLanguage('en')" :value="true" aria-role="listitem">
                            <div class="media is-align-items-center">
                                <img class="flag" src="@/assets/img/navbar/uk.png" />
                                <div class="media-content ml-3 is-flex is-align-self-center">
                                    <h3>{{ $t('navbar.english') }}</h3>
                                </div>
                            </div>
                        </b-dropdown-item>

                        <b-dropdown-item @click="setLanguage('pt_PT')" :value="false" aria-role="listitem">
                            <div class="media is-align-items-center">
                                <img class="flag" src="@/assets/img/navbar/pt.png" />
                                <div class="media-content ml-3 is-flex is-align-self-center">
                                    <h3>{{ $t('navbar.portuguese') }}</h3>
                                </div>
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import { localeChanged } from 'vee-validate';
    export default {
        name: 'Navbar',
        data() {
            return {
                currentLanguage: this.$store.getters['language/getLanguage'],
                isBurgerOpen: false,
            };
        },
        computed: {
            getCartCount() {
                // calculate cart count
                try {
                    let cartCount = 0;
                    this.$store.getters['cart/getCart'].cartProducts.forEach((item) => {
                        if (item.quantity) cartCount += item.quantity;
                        else cartCount += 1;
                    });
                    return cartCount;
                } catch (error) {
                    return 0;
                }
            },
        },
        methods: {
            setLanguage(language) {
                this.$store.commit('language/setLanguage', language);
                this.$i18n.locale = language;
                this.$moment.locale(language);
                localeChanged();
            },
            logOut() {
                this.isLoading = true;
                this.$store.commit('auth/resetUser');
                this.$router.push({ name: 'Login' });
                this.isBurgerOpen = false;
            },
            toggleBurguerOpen() {
                this.isBurgerOpen = !this.isBurgerOpen;
            },
            goTo(route) {
                this.$router.push(route);
                this.isBurgerOpen = false;
            },
        },
    };
</script>
<style scoped lang="scss">
    @import '~bulma/sass/utilities/mixins';
    .badge {
        transform: translate(10%, 0%) !important;
    }

    .badgeimage {
        width: 30px;
    }

    @include mobile {
        .badgeimage {
            width: 24px;
        }
    }

    .btn-flag {
        font-size: 0.6rem;
        padding-right: 0.5rem;
        padding-left: 0.3rem;
        padding-block: 1rem;
    }

    .flag {
        max-height: 0.75rem;
    }
</style>
