// API
import { tokenAuth } from '@/api/auth';
import { createCart } from '@/api/cart';
import userModel from '@/models/user';
import store from '@/store';
import router from '@/router';
export default {
    methods: {
        async onLoginSuccess(data, redirect = null) {
            store.commit('auth/setToken', data.jwt);
            if (!data.user.cart) {
                data.user.cart = await createCart({
                    user: data.user.id,
                }).then((response) => response.data.data);
            }
            this.setOnStore(data.user);
            if (redirect) {
                router.push(redirect);
            } else {
                router.push({ name: 'Home' });
            }
        },

        authWithToken() {
            tokenAuth()
                .then((response) => {
                    this.setOnStore(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    this.resetStores();
                    router.push({ name: 'Home' });
                });
        },
        setOnStore(_user) {
            const user = userModel(_user);
            store.commit('auth/setUser', user);
            store.commit('wishlist/setWishlist', user.wishlist);
            console.log(user.wishlist);
            store.commit('cart/setCart', user.cart);
        },
        resetStores() {
            store.commit('auth/resetUser');
            store.commit('wishlist/resetWishlist');
            store.commit('cart/resetCart');
        },
    },
};
