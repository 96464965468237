import axios from 'axios';
//! EXPORT YOUR VUE INSTANCE IN THE MAIN.JS FILE
import VueInstance from '@/main';
import authMixin from '@/mixins/auth';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
    baseURL: process.env.VUE_APP_API_URL,
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        let LOCALE = VueInstance.$i18n.locale;
        if (VueInstance.$i18n.locale.toLowerCase().includes('pt')) {
            LOCALE = 'pt';
        } else {
            LOCALE = 'en';
        }

        if (config.url.includes('?')) {
            config.url += '&locale=' + LOCALE;
        } else {
            config.url += '?locale=' + LOCALE;
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    },
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response;
    },
    function (error) {
        // Do something with response error
        if (error.response.status === 403) {
            authMixin.methods.resetStores();
            VueInstance.$router.push({ name: 'Login', query: { redirect: VueInstance.$route.path } });
        }
        return Promise.reject(error);
    },
);

const getHeaders = () => {
    let token = VueInstance.$store.getters['auth/getToken'];
    if (token) {
        return {
            Authorization: `Bearer ${token}`,
        };
    }
};

export function post(uri, body) {
    return _axios.post(uri, body, {
        headers: getHeaders(),
    });
}

export function put(uri, body) {
    return _axios.put(uri, body, {
        headers: getHeaders(),
    });
}

export function remove(uri, body) {
    return _axios.delete(uri, {
        body,
        headers: getHeaders(),
    });
}

export function get(uri) {
    return _axios.get(uri, {
        headers: getHeaders(),
    });
}

export default {
    get,
    post,
    put,
    remove,
};
