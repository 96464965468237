import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    key: process.env.VUE_APP_NAME,
});

Vue.use(Vuex);

import auth from './modules/auth';
import cart from './modules/cart';
import wishlist from './modules/wishlist';
import simulation from './modules/simulation';
import language from './modules/language';

export default new Vuex.Store({
    state: {
        windowWidth: 0,
        simulatorOpen: false,
    },
    getters: {
        windowWidth: (state) => state.windowWidth,
        simulatorOpen: (state) => state.simulatorOpen,
    },
    mutations: {
        setWindowWidth(state, width) {
            state.windowWidth = width;
        },
        setSimulatorOpen(state, open) {
            state.simulatorOpen = open;
        },
    },
    actions: {},
    modules: { auth, simulation, cart, wishlist, language },
    plugins: [vuexLocal.plugin],
});
