<template>
    <div class="home is-flex is-flex-direction-column">
        <Navbar />
        <router-view class="is-flex-grow-1" />
        <Footer class="mt-6" />
    </div>
</template>

<script>
    // @ is an alias to /src
    import Navbar from '@/components/ui/Navbar.vue';
    import Footer from '@/components/ui/Footer.vue';
    import auth from './mixins/auth';

    export default {
        mixins: [auth],
        components: {
            Navbar,
            Footer,
        },
        created() {
            // listener for window width change
            window.addEventListener('resize', this.onResize);
            this.onResize();
            this.$nextTick(() => {
                if (this.$store.getters['auth/getToken']) {
                    this.authWithToken();
                }
            });
        },
        methods: {
            onResize() {
                // save to store
                this.$store.commit('setWindowWidth', window.innerWidth);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .home {
        min-height: 100vh;
    }
</style>
