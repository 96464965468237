const MODEL = {
    id: 0,
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    shoeSizeType: '',
    shoeSize: 38,
    picture: null,
    wishlist: [],
    cart: null,
    simulations: [],
    shipping_phoneNumber: '',
    shipping_address: '',
    shipping_town: '',
    shipping_postalCode: '',
    billing_vat: '',
    shipping_firstName: '',
    shipping_lastName: '',
    shipping_country: '',
    billing_firstName: '',
    billing_lastName: '',
    billing_address: '',
    billing_postalCode: '',
    billing_town: '',
    billing_country: '',
    language: null,
};
import _ from 'lodash';
export default function (user = {}) {
    if (_.isArray(user)) {
        return user.map((user) => {
            return _.merge({}, MODEL, user);
        });
    } else {
        return _.merge({}, MODEL, user);
    }
}
