//initial state
// get browser language
let language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
if (language.includes('pt')) {
    language = 'pt';
} else {
    language = 'en';
}
const state = {
    language: language,
};

//getters
const getters = {
    getLanguage(state) {
        return state.language;
    },
};

// mutations
const mutations = {
    setLanguage(state, data) {
        state.language = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
