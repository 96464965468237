import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';
import './plugins/vee-validate';
import Buefy from 'buefy';
import './assets/scss/app.scss';
import appMixin from './mixins/app';
Vue.mixin(appMixin);
//Vee-Validate

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fas, far, fab);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.use(Buefy, {
    defaultIconComponent: 'font-awesome-icon',
    defaultIconPack: 'fas',
    defaultProgrammaticPromise: true,
});

Vue.config.productionTip = false;

i18n.locale = store.getters['language/getLanguage'];
import moment from 'moment';
moment.locale(i18n.locale);
Vue.prototype.$moment = moment;
import slugify from 'slugify';
Vue.prototype.$slugify = (str) => {
    return slugify(str, { lower: true });
};

Vue.prototype.$isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};


export default new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');

import * as VueGoogleMaps from 'vue2-google-maps';

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCeaH79C9AXLueZj4tVetE55ukJ07WFh5U',
    },
});
