import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/lookbook',
        name: 'Lookbook',
        component: () => import('@/views/Lookbook.vue'),
    },
    {
        path: '/product-details',
        name: 'ProductDetails',
        component: () => import('@/views/product/ProductDetails.vue'),
    },
    {
        path: '/product/:slug',
        name: 'ProductInformation',
        component: () => import('@/views/product/ProductInformation.vue'),
    },
    {
        path: '/simulator',
        name: 'Simulator',
        component: () => import('@/views/simulator/Simulator.vue'),
        /*       children: [
            {
                path: 'customize-product',
                name: 'CustomizeProduct',
                component: () => import('@/views/simulator/CustomizeProduct.vue'),
            },
        ], */
    },
    {
        path: '/customize-product',
        name: 'CustomizeProduct',
        component: () => import('@/views/simulator/CustomizeProduct.vue'),
    },
    {
        path: '/acessories',
        name: 'Acessories',
        component: () => import('@/views/Acessories.vue'),
    },
    {
        path: '/company',
        name: 'Company',
        component: () => import('@/views/Company.vue'),
    },
    {
        path: '/processes',
        name: 'Processes',
        component: () => import('@/views/Processes.vue'),
    },
    {
        path: '/rawmaterial',
        name: 'RawMaterial',
        component: () => import('@/views/RawMaterial.vue'),
        children: [
            {
                path: '',
                name: 'RawMaterials',
                component: () => import('@/views/rawmaterials/RawMaterials.vue'),
            },
            {
                path: 'cleaning-products',
                name: 'CleaningProducts',
                component: () => import('@/views/rawmaterials/CleaningProducts.vue'),
            },
            {
                path: 'our-leathers',
                name: 'OurLeathers',
                component: () => import('@/views/rawmaterials/OurLeathers.vue'),
            },
            {
                path: 'our-soles',
                name: 'OurSoles',
                component: () => import('@/views/rawmaterials/OurSoles.vue'),
            },
            {
                path: 'details',
                name: 'Details',
                component: () => import('@/views/rawmaterials/Details.vue'),
            },
            {
                path: 'our-models',
                name: 'OurModels',
                component: () => import('@/views/rawmaterials/OurModels.vue'),
            },
        ],
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/Contact.vue'),
    },
    {
        path: '/cart',
        name: 'Cart',
        component: () => import('@/views/Cart.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/components/account/Login.vue'),
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/components/account/Register.vue'),
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/components/account/ForgotPassword.vue'),
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('@/components/account/ResetPassword.vue'),
    },
    {
        path: '/myaccount',
        name: 'MyAccount',
        component: () => import('@/components/account/myaccount/MyAccount.vue'),

        /* children: [
			{ path: 'profile', name: 'Profile', component: () => import('@/components/account/myaccount/Profile.vue') },
		]  */
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: () => import('@/views/Checkout.vue'),
    },
    {
        path: '/purchase-procedure',
        name: 'PurchaseProcedure',
        component: () => import('@/views/footerPages/PurchaseProcedure.vue'),
    },
    {
        path: '/exchanges-returns',
        name: 'ExchangesReturns',
        component: () => import('@/views/footerPages/ExchangesReturns.vue'),
    },
    {
        path: '/payment-methods',
        name: 'PaymentMethods',
        component: () => import('@/views/footerPages/PaymentMethods.vue'),
    },
    {
        path: '/privcy-policy',
        name: 'PrivacyPolicy',
        component: () => import('@/views/footerPages/PrivacyPolicy.vue'),
    },
    {
        path: '/marketing-communication',
        name: 'MarketingCommunication',
        component: () => import('@/views/footerPages/MarketingCommunication.vue'),
    },
    {
        path: '/shipping',
        name: 'Shipping',
        component: () => import('@/views/footerPages/Shipping.vue'),
    },
    {
        path: '/general-conditions',
        name: 'GeneralConditions',
        component: () => import('@/views/footerPages/GeneralConditions.vue'),
    },
    {
        path: '/order-confirmation',
        name: 'OrderConfirmation',
        component: () => import('@/views/OrderConfirmation.vue'),
    },
    {
        path: '/measurement-process',
        name: 'MeasurementProcess',
        component: () => import('@/views/product/MeasurementProcess.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes,
});

export default router;
