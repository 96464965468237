<template>
    <div>
        <footer class="footer has-background-grey-light">
            <div class="container px-6-desktop my-5">
                <div class="columns is-mobile is-multiline">
                    <div class="column is-3-desktop is-3-tablet is-12-mobile">
                        <figure class="image is-128x128">
                            <img src="@/assets/img/logo.png" />
                        </figure>
                        <h1 class="is-size-5 is-size-6-mobile has-text-white has-text-weight-medium mt-6">
                            {{ $t('footer.followUs') }}
                        </h1>
                        <div class="is-flex">
                            <a href="https://www.facebook.com/MJP-110457123702916" target="__blank" class="m-2">
                                <b-button class="facebook-btn" icon-pack="fab" icon-left="facebook-f"></b-button>
                            </a>
                            <a href="https://www.instagram.com/m.j.p.19/" target="__blank" class="m-2">
                                <b-button class="instagram-btn">
                                    <i class="fab fa-instagram"></i>
                                </b-button>
                            </a>
                        </div>
                    </div>
                    <div class="column is-4-desktop is-4-tablet is-12-mobile">
                        <h1
                            class="title is-4 is-size-5-mobile is-size-5-tablet has-text-white has-text-weight-medium mt-2-desktop mt-4-mobile">
                            {{ $t('footer.informationHelp') }}
                        </h1>
                        <b-button
                            @click="$router.push({ name: 'PurchaseProcedure' })"
                            expanded
                            class="is-place-content-start has-text-white is-ghost">
                            {{ $t('footer.purchaseProcedure') }}
                        </b-button>
                        <b-button
                            @click="$router.push({ name: 'ExchangesReturns' })"
                            expanded
                            class="is-place-content-start has-text-white is-ghost">
                            {{ $t('footer.exchangeReturn') }}
                        </b-button>
                        <b-button
                            @click="$router.push({ name: 'PaymentMethods' })"
                            expanded
                            class="is-place-content-start has-text-white is-ghost">
                            {{ $t('footer.paymentMethod') }}
                        </b-button>
                        <!-- <b-button expanded class="is-place-content-start has-text-white is-ghost">
                            {{ $t('footer.paymentMethod') }}
                        </b-button> -->
                        <b-button
                            @click="$router.push({ name: 'PrivacyPolicy' })"
                            expanded
                            class="is-place-content-start has-text-white is-ghost">
                            {{ $t('footer.privacy') }}
                        </b-button>
                        <!-- <b-button expanded class="is-place-content-start has-text-white is-ghost">
                            {{ $t('footer.shipping') }}
                        </b-button> -->
                        <b-button
                            @click="$router.push({ name: 'MarketingCommunication' })"
                            expanded
                            class="is-place-content-start has-text-white is-ghost">
                            {{ $t('footer.marketingComunication') }}
                        </b-button>
                        <b-button
                            @click="$router.push({ name: 'Shipping' })"
                            expanded
                            class="is-place-content-start has-text-white is-ghost">
                            {{ $t('footer.shipping') }}
                        </b-button>
                        <b-button
                            @click="$router.push({ name: 'GeneralConditions' })"
                            expanded
                            class="is-place-content-start has-text-white is-ghost">
                            {{ $t('footer.generalConditions') }}
                        </b-button>
                        <b-button
                            expanded
                            @click="$router.push({ name: 'Contact' })"
                            class="is-place-content-start has-text-white is-ghost">
                            {{ $t('footer.contactUs') }}
                        </b-button>
                    </div>
                    <div class="column is-5-desktop is-5-tablet is-12-mobile">
                        <h1
                            class="title is-4 is-size-5-mobile is-size-5-tablet has-text-white mt-10-desktop has-text-weight-medium">
                            {{ $t('footer.paymentOptions') }}
                        </h1>
                        <div class="columns is-mobile mt-2">
                            <div class="column is-2-desktop">
                                <figure>
                                    <img src="@/assets/img/payment/visa.png" />
                                </figure>
                            </div>
                            <div class="column is-2-desktop">
                                <figure>
                                    <img src="@/assets/img/payment/mastercard.png" />
                                </figure>
                            </div>
                            <div class="column is-2-desktop">
                                <figure>
                                    <img src="@/assets/img/payment/maestro.png" />
                                </figure>
                            </div>
                            <div class="column is-2-desktop">
                                <figure>
                                    <img src="@/assets/img/payment/visaE.png" />
                                </figure>
                            </div>
                            <div class="column is-2-desktop">
                                <figure>
                                    <img src="@/assets/img/payment/paypal.png" />
                                </figure>
                            </div>
                        </div>
                        <h1 class="title is-4 is-size-5-mobile is-size-5-tablet has-text-white has-text-weight-medium">
                            {{ $t('footer.secureAndWorry') }}
                        </h1>
                        <div class="columns is-mobile mt-2">
                            <div class="column is-2-desktop">
                                <figure>
                                    <img src="@/assets/img/payment/securecode.png" />
                                </figure>
                            </div>
                            <div class="column is-2-desktop">
                                <figure>
                                    <img src="@/assets/img/payment/verifiedVisa.png" />
                                </figure>
                            </div>
                            <div class="column is-2-desktop">
                                <figure>
                                    <img src="@/assets/img/payment/e.png" />
                                </figure>
                            </div>
                            <div class="column is-2-desktop">
                                <figure>
                                    <img src="@/assets/img/payment/verisign.png" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <footer class="footer has-background-primary has-text-centered">
            <h1 class="has-text-white has-text-weight-medium">{{ currentYear }}{{ $t('footer.copyRight') }}</h1>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        data() {
            return {
                currentYear: new Date().getFullYear(),
            };
        },
    };
</script>

<style scoped>
    .is-place-content-start {
        place-content: flex-start;
    }
</style>
