import _ from 'lodash';
export const state = () => ({
    simulation: {
        id: null,
        user: null,
        product: null,
        picture: null,
        fields: {},
        pdf: null,
    },
});

export const getters = {
    getSimulation: (state) => {
        return state.simulation;
    },
};

export const mutations = {
    setProduct(state, payload) {
        state.simulation.product = _.merge({}, payload);
    },
    setSimulation: (state, payload) => {
        state.simulation = _.merge(state.simulation, payload);
    },
    clearSimulation(state) {
        state.simulation = {
            id: null,
            user: null,
            product: null,
            picture: null,
            pdf: null,
        };
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
